import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  useTheme,
} from "@mui/material";
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import { memo, useCallback } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate } from "rocon/react";
import { topLevelRoutes } from "../routes";
import { auth } from "../utils/firebase";

interface Props {
  email: string;
}

export const Navigation = memo(function Navigation({ email }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { showBoundary } = useErrorBoundary();

  const onPasswordReset = useCallback(() => {
    const isConfirmed = confirm(
      "パスワードリセットのメールを送信します。よろしいですか？\n（メールが届かない場合は、迷惑メールフォルダをご確認ください。）",
    );

    if (!isConfirmed) {
      return;
    }

    sendPasswordResetEmail(auth, email).catch(showBoundary);
  }, [email, showBoundary]);

  const onSignOut = useCallback(() => {
    signOut(auth)
      .then(() => {
        navigate(topLevelRoutes.exactRoute);
      })
      .catch(showBoundary);
  }, [showBoundary, navigate]);

  return (
    <Box
      component="nav"
      minHeight="100vh"
      sx={{ borderRight: "1px solid", borderColor: theme.palette.grey[300] }}
    >
      <List>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.products);
          }}
          selected={location.pathname.startsWith("/products")}
        >
          <ListItemText primary="商品" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.collections);
          }}
          selected={location.pathname.startsWith("/collections")}
        >
          <ListItemText primary="コレクション" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.shipping_days);
          }}
          selected={location.pathname.startsWith("/shipping_days")}
        >
          <ListItemText primary="発送予定日" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.maintenances);
          }}
          selected={location.pathname.startsWith("/maintenances")}
        >
          <ListItemText primary="メンテナンス" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.tasks);
          }}
          selected={location.pathname.startsWith("/tasks")}
        >
          <ListItemText primary="タスク" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.keywords);
          }}
          selected={location.pathname.startsWith("/keywords")}
        >
          <ListItemText primary="キーワード" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.carousel_banners);
          }}
          selected={location.pathname.startsWith("/carousel_banners")}
        >
          <ListItemText primary="カルーセルバナー" />
        </ListItemButton>
        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.epos_campaigns);
          }}
          selected={location.pathname.startsWith("/epos_campaigns")}
        >
          <ListItemText primary="epos入会特典テキスト" />
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            navigate(topLevelRoutes._.users);
          }}
          selected={location.pathname.startsWith("/users")}
        >
          <ListItemText primary="ユーザー" />
        </ListItemButton>
        <Divider />
        <ListSubheader>{email}</ListSubheader>
        <ListItemButton onClick={onPasswordReset}>
          <ListItemText primary="パスワード再設定" />
        </ListItemButton>
        <ListItemButton onClick={onSignOut}>
          <ListItemText primary="ログアウト" />
        </ListItemButton>
      </List>
    </Box>
  );
});
