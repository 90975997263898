import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { RoconRoot } from "rocon/react";
import { ErrorBoundary } from "./ErrorBoundary";
import { Layout } from "./layout";

const typography = {
  h1: { fontSize: "3rem" },
  h2: { fontSize: "2.2rem" },
  h3: { fontSize: "1.75rem" },
  h4: { fontSize: "1.5rem" },
  h5: { fontSize: "1.4rem" },
  h6: { fontSize: "1.3rem" },
};

const theme = createTheme({
  typography,
  components: {
    MuiTypography: {
      styleOverrides: { ...typography },
    },
  },
});

export const App = (): JSX.Element => {
  return (
    <>
      <CssBaseline />
      <RoconRoot>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <Layout />
          </ErrorBoundary>
        </ThemeProvider>
      </RoconRoot>
    </>
  );
};
