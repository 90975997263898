import { lazy } from "react";
import Rocon from "rocon/react";
import { Construction } from "./components/Construction";

const Exact = lazy(async () => await import("./pages/Exact"));

const ProductsListContainer = lazy(
  async () => await import("./pages/products/list"),
);

const CollectionsListContainer = lazy(
  async () => await import("./pages/collections/list"),
);
const CollectionEditContainer = lazy(
  async () => await import("./pages/collections/edit"),
);
const CollectionNewContainer = lazy(
  async () => await import("./pages/collections/new"),
);
const CollectionProductsListContainer = lazy(
  async () => await import("./pages/collections/products/list"),
);
const CollectionProductsEditContainer = lazy(
  async () => await import("./pages/collections/products/edit"),
);

const ShippingDaysListContainer = lazy(
  async () => await import("./pages/shippingDays/list"),
);
const ShippingDayEditContainer = lazy(
  async () => await import("./pages/shippingDays/edit"),
);
const ShippingDayNewContainer = lazy(
  async () => await import("./pages/shippingDays/new"),
);

const MaintenancesListContainer = lazy(
  async () => await import("./pages/maintenances/list"),
);
const MaintenanceEditContainer = lazy(
  async () => await import("./pages/maintenances/edit"),
);
const MaintenanceNewContainer = lazy(
  async () => await import("./pages/maintenances/new"),
);

const TasksListContainer = lazy(async () => await import("./pages/tasks/list"));

const KeywordsListContainer = lazy(
  async () => await import("./pages/keywords/list"),
);
const KeywordsEditContainer = lazy(
  async () => await import("./pages/keywords/edit"),
);

const CarouselBannersListContainer = lazy(
  async () => await import("./pages/carouselBanners/list"),
);
const CarouselBannersEditContainer = lazy(
  async () => await import("./pages/carouselBanners/edit"),
);

const EposCampaignsListContainer = lazy(
  async () => await import("./pages/eposCampaigns/list"),
);
const EposCampaignsEditContainer = lazy(
  async () => await import("./pages/eposCampaigns/edit"),
);

// const UsersListContainer = lazy(async () => await import("./pages/users/list"));
// const UserNewContainer = lazy(async () => await import("./pages/users/new"));

export const topLevelRoutes = Rocon.Path()
  .exact({ action: () => <Exact /> })
  .route("products")
  .route("collections")
  .route("shipping_days")
  .route("maintenances")
  .route("tasks")
  .route("keywords")
  .route("carousel_banners")
  .route("epos_campaigns")
  .route("users");

export const productsRoutes = topLevelRoutes._.products
  .attach(Rocon.Path())
  .exact({ action: () => <ProductsListContainer /> });

export const collectionsRoutes = topLevelRoutes._.collections
  .attach(Rocon.Path())
  .exact({ action: () => <CollectionsListContainer /> })
  .route("new", (route) => route.action(() => <CollectionNewContainer />))
  .any("id");

export const collectionsAnyRoutes = collectionsRoutes.anyRoute
  .attach(Rocon.Path())
  .route("products", (route) =>
    route.action(({ id }) => <CollectionProductsListContainer id={id} />),
  )
  .route("edit", (route) =>
    route.action(({ id }) => <CollectionEditContainer id={id} />),
  );

export const collectionsProductsRoutes = collectionsAnyRoutes._.products
  .attach(Rocon.Path())
  .route("edit", (route) =>
    route.action(({ id }) => <CollectionProductsEditContainer id={id} />),
  );

export const shippingDaysRoutes = topLevelRoutes._.shipping_days
  .attach(Rocon.Path())
  .exact({ action: () => <ShippingDaysListContainer /> })
  .route("new", (route) => route.action(() => <ShippingDayNewContainer />))
  .any("id");

export const shippingDaysAnyRoutes = shippingDaysRoutes.anyRoute
  .attach(Rocon.Path())
  .route("edit", (route) =>
    route.action(({ id }) => <ShippingDayEditContainer id={id} />),
  );

export const maintenancesRoutes = topLevelRoutes._.maintenances
  .attach(Rocon.Path())
  .exact({ action: () => <MaintenancesListContainer /> })
  .route("new", (route) => route.action(() => <MaintenanceNewContainer />))
  .any("id");

export const maintenancesAnyRoutes = maintenancesRoutes.anyRoute
  .attach(Rocon.Path())
  .route("edit", (route) =>
    route.action(({ id }) => <MaintenanceEditContainer id={id} />),
  );

export const tasksRoutes = topLevelRoutes._.tasks
  .attach(Rocon.Path())
  .exact({ action: () => <TasksListContainer /> });

export const keywordsRoutes = topLevelRoutes._.keywords
  .attach(Rocon.Path())
  .exact({ action: () => <KeywordsListContainer /> })
  .route("edit", (route) => route.action(() => <KeywordsEditContainer />));

export const carouselBannersRoutes = topLevelRoutes._.carousel_banners
  .attach(Rocon.Path())
  .exact({ action: () => <CarouselBannersListContainer /> })
  .route("edit", (route) =>
    route.action(() => <CarouselBannersEditContainer />),
  );

export const eposCampaignsRoutes = topLevelRoutes._.epos_campaigns
  .attach(Rocon.Path())
  .exact({ action: () => <EposCampaignsListContainer /> })
  .route("edit", (route) => route.action(() => <EposCampaignsEditContainer />));

export const usersRoutes = topLevelRoutes._.users
  .attach(Rocon.Path())
  .exact({ action: () => <Construction /> })
  .route("new", (route) => route.action(() => <Construction />));
