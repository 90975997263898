import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";

export interface LoginFormValues {
  email: string;
  password: string;
}

interface Props {
  open: boolean;
  disabled: boolean;
  errorMessage?: string;
  onSubmit: SubmitHandler<LoginFormValues>;
}

export const LoginDialog = memo(function LoginDialog({
  open,
  disabled,
  errorMessage,
  onSubmit,
}: Props) {
  const { register, handleSubmit } = useForm<LoginFormValues>();

  return (
    <Dialog open={open} fullWidth>
      <DialogContent>
        <Stack spacing={2}>
          {errorMessage === undefined ? null : (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
          )}
          <TextField
            size="small"
            label="メールアドレス"
            type="email"
            variant="standard"
            {...register("email")}
          />
          <TextField
            size="small"
            label="パスワード"
            helperText="パスワードを忘れた場合は、開発チームにお問合せください。"
            type="password"
            variant="standard"
            {...register("password")}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          onClick={handleSubmit(onSubmit)}
          disabled={disabled}
        >
          ログイン
        </Button>
      </DialogActions>
    </Dialog>
  );
});
