import { Box, Stack } from "@mui/material";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { memo, useCallback, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { type SubmitHandler } from "react-hook-form";
import { Loading } from "../components/Loading";
import { auth } from "../utils/firebase";
import { LoginDialog, type LoginFormValues } from "./LoginDialog";
import { Navigation } from "./Navigation";
import { Routes } from "./Routes";

type Session =
  | { status: "checking" }
  | { status: "logging in" }
  | { status: "logged in"; email: string };

type Login =
  | { status: "loading" }
  | { status: "failed"; errorMessage: string }
  | { status: "terminated" };

export const Layout = memo(function Layout() {
  const { showBoundary } = useErrorBoundary();
  const [session, setSession] = useState<Session>({ status: "checking" });
  const [login, setLogin] = useState<Login>({ status: "terminated" });

  const onSubmit = useCallback<SubmitHandler<LoginFormValues>>(
    ({ email, password }) => {
      (async () => {
        setLogin({ status: "loading" });
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password,
        ).catch(() => {
          setLogin({
            status: "failed",
            errorMessage: "メールアドレスまたはパスワードが違います。",
          });
          return undefined;
        });

        if (userCredential === undefined) {
          return;
        }

        if (userCredential.user.email === null) {
          throw new Error("user.email が null です。");
        }
        setLogin({ status: "terminated" });
        setSession({
          status: "logged in",
          email: userCredential.user.email,
        });
      })().catch(showBoundary);
    },
    [showBoundary],
  );

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user === null || user.email === null) {
        setSession({ status: "logging in" });
      } else {
        setSession({ status: "logged in", email: user.email });
      }
    });
  }, []);

  return (
    <>
      <LoginDialog
        open={session.status === "logging in"}
        disabled={login.status === "loading"}
        errorMessage={
          login.status === "failed" ? login.errorMessage : undefined
        }
        onSubmit={onSubmit}
      />

      {session.status === "logged in" ? (
        <Stack direction="row">
          <Navigation email={session.email} />
          <Box component="main" padding={2} flexGrow={1} minWidth={0}>
            <Routes />
          </Box>
        </Stack>
      ) : (
        <Box height="100vh">
          <Loading />
        </Box>
      )}
    </>
  );
});
