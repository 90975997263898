import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { getHost } from "./utils/getHost";

dayjs.extend(utc);
dayjs.extend(timezone);

Sentry.init({
  enabled: process.env.NODE_ENV !== "development",
  dsn: "https://e9d25187343540bb889571e078882931@o1125162.ingest.sentry.io/4504354650456064",
  integrations: [
    new Sentry.BrowserTracing(),
    new Replay({
      maskAllText: true,
      blockAllMedia: true,
      networkDetailAllowUrls: [getHost()],
    }),
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

const app = document.getElementById("app");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(app!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
