"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locationDiff = locationDiff;

/**
 * Check whether `location` is based on `base`.
 * @returns Remaining part of `location` if based, undefined otherwise.
 */
function locationDiff(base, location) {
  var _base$state;

  const basePathSegments = base.pathname.split("/").filter(v => v !== "");
  const locationPathSegments = location.pathname.split("/").filter(v => v !== ""); // Path is not suffix

  if (!isSuffix(basePathSegments, locationPathSegments)) {
    return undefined;
  }

  const remainingPath = "/" + locationPathSegments.slice(basePathSegments.length).join("/");
  const baseSearch = new URLSearchParams(base.search);
  const locationSearch = new URLSearchParams(location.search);

  for (const [key, value] of baseSearch.entries()) {
    if (locationSearch.get(key) !== value) {
      // has different value
      return undefined;
    }

    locationSearch.delete(key);
  }

  const result = { ...location,
    pathname: remainingPath
  };
  const search = locationSearch.toString();

  if (search !== "") {
    result.search = "?" + search;
  } else if (result.search !== undefined) {
    result.search = "";
  }

  const diffState = {};
  const baseState = (_base$state = base.state) !== null && _base$state !== void 0 ? _base$state : {};

  for (const [key, value] of Object.entries((_location$state = location.state) !== null && _location$state !== void 0 ? _location$state : {})) {
    var _location$state;

    if (baseState[key] !== undefined) {
      if (baseState[key] !== value) {
        return undefined;
      }

      continue;
    }

    diffState[key] = value;
  }

  if (location.state !== null) {
    result.state = diffState;
  }

  return result;
}

function isSuffix(arr1, arr2) {
  return arr1.every((v, i) => v === arr2[i]);
}