"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  validator: true,
  Link: true,
  Redirect: true,
  RoconRoot: true,
  isLocationNotFoundError: true,
  LocationNotFoundError: true,
  useHistory: true,
  useLocation: true,
  useNavigate: true,
  useRoutes: true
};
Object.defineProperty(exports, "Link", {
  enumerable: true,
  get: function () {
    return _Link.Link;
  }
});
Object.defineProperty(exports, "Redirect", {
  enumerable: true,
  get: function () {
    return _Redirect.Redirect;
  }
});
Object.defineProperty(exports, "RoconRoot", {
  enumerable: true,
  get: function () {
    return _RoconRoot.RoconRoot;
  }
});
Object.defineProperty(exports, "isLocationNotFoundError", {
  enumerable: true,
  get: function () {
    return _LocationNotFoundError.isLocationNotFoundError;
  }
});
Object.defineProperty(exports, "LocationNotFoundError", {
  enumerable: true,
  get: function () {
    return _LocationNotFoundError.LocationNotFoundError;
  }
});
Object.defineProperty(exports, "useHistory", {
  enumerable: true,
  get: function () {
    return _useHistory.useHistory;
  }
});
Object.defineProperty(exports, "useLocation", {
  enumerable: true,
  get: function () {
    return _useLocation.useLocation;
  }
});
Object.defineProperty(exports, "useNavigate", {
  enumerable: true,
  get: function () {
    return _useNavigate.useNavigate;
  }
});
Object.defineProperty(exports, "useRoutes", {
  enumerable: true,
  get: function () {
    return _useRoutes.useRoutes;
  }
});
exports.validator = void 0;

var _validator = _interopRequireWildcard(require("../validator"));

exports.validator = _validator;

var _Link = require("./components/Link");

var _Redirect = require("./components/Redirect");

var _RoconRoot = require("./components/RoconRoot");

var _LocationNotFoundError = require("./errors/LocationNotFoundError");

var _useHistory = require("./hooks/useHistory");

var _useLocation = require("./hooks/useLocation");

var _useNavigate = require("./hooks/useNavigate");

var _useRoutes = require("./hooks/useRoutes");

var _shorthand = require("./shorthand");

Object.keys(_shorthand).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _shorthand[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _shorthand[key];
    }
  });
});

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }