import ConstructionIcon from "@mui/icons-material/Construction";
import { Stack, Typography } from "@mui/material";
import { memo } from "react";

export const Construction = memo(function Construction() {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <ConstructionIcon fontSize="large" />
      <Typography variant="inherit">工事中</Typography>
    </Stack>
  );
});
