import { Suspense } from "react";
import { useRoutes } from "rocon/react";
import { Loading } from "../components/Loading";
import { topLevelRoutes } from "../routes";

export const Routes = (): JSX.Element | null => {
  const routes = useRoutes(topLevelRoutes);

  return <Suspense fallback={<Loading />}>{routes}</Suspense>;
};
