import { Box, Button, Typography } from "@mui/material";
import { type ReactNode, useCallback } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  type FallbackProps,
} from "react-error-boundary";
import { isLocationNotFoundError, useNavigate } from "rocon/react";
import { topLevelRoutes } from "./routes";
import { isRequestError } from "./utils/RequestError";

interface Props {
  children: ReactNode;
}

const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps): JSX.Element => {
  if (isRequestError(error)) {
    return (
      <Box padding={1}>
        <Typography marginBottom={1}>
          サーバーとの通信中にエラーが発生しました。原因に心当たりがない場合は、#ec-console
          までお問い合わせください。
        </Typography>
        <Typography marginBottom={1}>
          {`Error ${error.status}: ${error.message}`}
        </Typography>
        <Button size="small" onClick={resetErrorBoundary}>
          トップに戻る
        </Button>
      </Box>
    );
  } else if (isLocationNotFoundError(error)) {
    return (
      <Box padding={1}>
        <Typography marginBottom={1}>ページが存在しません。</Typography>
        <Button size="small" onClick={resetErrorBoundary}>
          トップに戻る
        </Button>
      </Box>
    );
  } else {
    return (
      <Box padding={1}>
        <Typography marginBottom={1}>
          未知のエラーが発生しました。#ec-console までお問合せください。
        </Typography>
        <Button size="small" onClick={resetErrorBoundary}>
          トップに戻る
        </Button>
      </Box>
    );
  }
};

export const ErrorBoundary = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const onReset = useCallback(() => {
    navigate(topLevelRoutes.exactRoute);
  }, [navigate]);

  return (
    <ReactErrorBoundary
      {...props}
      FallbackComponent={ErrorFallback}
      onReset={onReset}
    />
  );
};
